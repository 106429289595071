"use client"

import * as React from "react"

import { DMBadge, DMBadgeProps, DMColorInput } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheck } from "@supernovaio/icons"

import { Toggle } from "@radix-ui/react-toggle"

type AllColorOptions = Extract<DMBadgeProps["color"], string>

type DMColorSelectionGridColors = Exclude<
  AllColorOptions,
  "primary" | "positive" | "critical" | "upgrade" | "neutral"
>

const colorSelectionGridColors: DMColorSelectionGridColors[] = [
  "blue",
  "cyan",
  "fuchsia",
  "green",
  "grey",
  "lightGrey",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
  "yellow",
]

const randomColorSelectionGridColor = (
  usedColors: DMColorSelectionGridColors[] = []
): DMColorSelectionGridColors => {
  const availableColors = colorSelectionGridColors.filter(
    (color) => !usedColors.includes(color)
  )

  const colorsToPickFrom =
    availableColors.length === 0 ? colorSelectionGridColors : availableColors

  return colorsToPickFrom[
    Math.floor(Math.random() * colorsToPickFrom.length)
  ] as DMColorSelectionGridColors
}

type DMColorSelectionGridProps = {
  // This can either contain a hex code, or a preset name
  selectedColor?: string | DMColorSelectionGridColors
  onChange?: (color: string) => void
}

function DMColorSelectionGrid(props: DMColorSelectionGridProps) {
  const { selectedColor, onChange } = props

  const sureColorChanged = (color: DMColorSelectionGridColors | string) => {
    if (onChange) {
      onChange(color)
    }
  }

  const customColor = () => {
    if (
      typeof selectedColor === "string" &&
      !colorSelectionGridColors.includes(selectedColor)
    ) {
      return selectedColor
    }

    return null
  }

  return (
    <div className="inline-flex flex-col">
      <div className="inline-grid shrink grid-cols-6 gap-2 p-2">
        {colorSelectionGridColors.map((color) => (
          <DMColorSelectionGridItem
            key={color}
            color={color}
            isSelected={selectedColor === color}
            onSelect={sureColorChanged}
          />
        ))}
      </div>
      <div className="bg-neutral-faded inline-flex flex-row p-8">
        <div className="text-neutral text-body justify-between p-8 font-semibold">
          Custom
        </div>
        <div className="max-w-[136px]">
          <DMColorInput
            hasPlaceholder
            value={customColor()}
            onChange={(color) => color && sureColorChanged(color)}
          />
        </div>
      </div>
    </div>
  )
}

DMColorSelectionGrid.displayName = "DMColorSelectionGrid"

const ToggleButton = React.forwardRef<
  React.ElementRef<typeof Toggle>,
  React.ComponentPropsWithoutRef<typeof Toggle>
>(({ className, children, ...props }, ref) => (
  <Toggle ref={ref} className={className} {...props}>
    {children}
  </Toggle>
))

ToggleButton.displayName = Toggle.displayName

function DMColorSelectionGridItem(props: {
  color: DMColorSelectionGridColors
  isSelected?: boolean
  onSelect: (color: DMColorSelectionGridColors) => void
}) {
  const { color, isSelected = false, onSelect } = props

  return (
    <ToggleButton
      className={cn(
        "w-form-medium h-form-medium relative flex items-center justify-center rounded outline-none transition focus-visible:ring-2",
        {
          "hover:bg-neutral": !isSelected,
          "bg-primary-faded inner-border-primary-faded inner-border":
            isSelected,
        }
      )}
      pressed={isSelected}
      onPressedChange={() => {
        onSelect(color)
      }}
    >
      <div className={cn("", { "clip-right-bottom-corner": isSelected })}>
        <DMBadge color={color} size="small">
          Aa
        </DMBadge>
      </div>
      {isSelected && (
        <IconCheck
          className="text-primary absolute bottom-4 right-2"
          height={12}
          width={12}
        />
      )}
    </ToggleButton>
  )
}

export {
  colorSelectionGridColors,
  randomColorSelectionGridColor,
  DMColorSelectionGrid,
  DMColorSelectionGridItem,
  type DMColorSelectionGridColors,
}
