"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheck } from "@supernovaio/icons"
import { IconType } from "@supernovaio/icons/types"

import * as SelectPrimitive from "@radix-ui/react-select"

import { DMIcon } from "../../DMIcon"
import { getLineClampClassname, SupportedLineClamp } from "../../DMMenu/utils"

interface DMSelectItemProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>,
    "disabled"
  > {
  /** Description to display below the label */
  description?: string
  /** Icon to display on the left side of the menu item */
  icon?: IconType
  /** Custom content that appears on the right side of the menu item */
  endSlot?: React.ReactNode
  /** Custom content that appears between the icon and the label */
  startSlot?: React.ReactNode
  /** Sets how many lines of description should be displayed before it gets truncated. */
  maxDescriptionLines?: SupportedLineClamp
  /** Whether this item is currently disabled. */
  isDisabled?: boolean
}

const DMSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  DMSelectItemProps
>(
  (
    {
      description,
      icon,
      endSlot,
      startSlot,
      isDisabled,
      className,
      maxDescriptionLines = 1,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <SelectPrimitive.Item
        ref={ref}
        className={cn(
          "focus:bg-neutral focus:text-neutral text-body data-[disabled]:opacity-disabled group relative flex min-h-[32px] cursor-pointer select-none gap-8 rounded px-8 py-[6px] outline-none transition-colors",
          isDisabled && "opacity-disabled cursor-not-allowed",
          !description && "items-center",
          className
        )}
        disabled={isDisabled}
        {...props}
      >
        <div
          className={cn(
            "flex shrink-0 items-center gap-4",
            description && "h-[20px]"
          )}
        >
          <div className="w-icon-small h-icon-small">
            <SelectPrimitive.ItemIndicator asChild>
              <DMIcon svg={IconCheck} />
            </SelectPrimitive.ItemIndicator>
          </div>
          {icon && (
            <div className="w-icon-small h-icon-small">
              <DMIcon svg={icon} />
            </div>
          )}
        </div>

        {startSlot && <div className="shrink-0">{startSlot}</div>}
        <div className="flex flex-grow items-center gap-8">
          <div className="flex flex-col gap-2">
            <div className={cn("text-body line-clamp-1 font-semibold")}>
              <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
            </div>
            {description && (
              <div
                className={cn(
                  "text-neutral-faded text-body-small font-semibold",
                  getLineClampClassname(maxDescriptionLines)
                )}
              >
                {description}
              </div>
            )}
          </div>
        </div>
        {endSlot && <div className="shrink-0">{endSlot}</div>}
      </SelectPrimitive.Item>
    )
  }
)

DMSelectItem.displayName = SelectPrimitive.Item.displayName

export { DMSelectItem }
