import React, { forwardRef } from "react"

import { IconCaret } from "@supernovaio/icons"

import { Row } from "@tanstack/react-table"

import DMDataTableTd from "../DMDataTableTd"
import DMDataTableTr from "../DMDataTableTr"

import DMDataTableTitleCell, { TitleCellProps } from "./DMDataTableTitleCell"

export type GroupRowProps = {
  row: Row<any>
  customContent?: React.ReactNode
  allowTransition?: boolean
} & TitleCellProps

const DMDataTableGroupRow = forwardRef<HTMLTableRowElement, GroupRowProps>(
  ({ row, customContent, allowTransition, ...props }, ref) => {
    return (
      <DMDataTableTr
        ref={ref}
        className="group"
        onClick={() => row.toggleExpanded()}
      >
        {customContent || (
          <>
            <DMDataTableTitleCell
              isGroup
              className="h-table-group-cell bg-blur-neutral-faded group-hover/clickable-row:bg-blur-neutral-highlighted transition-colors has-dropdown-open:bg-blur-neutral-highlighted"
              depth={row.depth}
              style={{
                maxWidth: row.getAllCells()[0]?.column.getSize(),
                width: row.getAllCells()[0]?.column.getSize(),
              }}
              suffix={
                row.getIsExpanded() ? (
                  <IconCaret className="text-neutral-faded rotate-90 transition-transform" />
                ) : (
                  <IconCaret className="text-neutral-faded transition-transform" />
                )
              }
              {...props}
            />
            <DMDataTableTd
              allowTransition={allowTransition}
              className="h-table-group-cell bg-blur-neutral-faded group-hover/clickable-row:bg-blur-neutral-highlighted transition-colors"
              colSpan={row.getAllCells().length}
            />
          </>
        )}
      </DMDataTableTr>
    )
  }
)

DMDataTableGroupRow.displayName = "DMGroupRow"

export default DMDataTableGroupRow
