"use client"

import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SelectPrimitive from "@radix-ui/react-select"

import { minWidthVariants } from "../../styling/popovers"

import { DMSelectBaseProps, DMSelectProps } from "./DMSelect.types"
import { DMSelectTriggerButton } from "./index"

import { VariantProps } from "class-variance-authority"

const DMSelectValue = SelectPrimitive.Value

function DMSelectBase({
  isDefaultOpen,
  isOpen,
  isDisabled,
  ...props
}: DMSelectBaseProps) {
  return (
    <SelectPrimitive.Root
      defaultOpen={isDefaultOpen}
      disabled={isDisabled}
      open={isOpen}
      {...props}
    />
  )
}

DMSelectBase.displayName = "DMSelectBase"

const DMSelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> &
    Pick<DMSelectProps, "placeholder">
>(({ placeholder, children, ...props }, ref) => (
  <SelectPrimitive.Trigger ref={ref} {...props} asChild>
    <DMSelectTriggerButton>
      <DMSelectValue placeholder={placeholder || "Select"}>
        {children}
      </DMSelectValue>
    </DMSelectTriggerButton>
  </SelectPrimitive.Trigger>
))

DMSelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const DMSelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> &
    VariantProps<typeof minWidthVariants> & {
      viewportClassName?: string
    }
>(
  (
    {
      className,
      viewportClassName,
      children,
      position = "popper",
      size = "small",
      ...props
    },
    ref
  ) => (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cn(
          "bg-elevation-overlay text-neutral animate-in fade-in-80 inner-border-neutral-faded shadow-overlay inner-border relative z-50 overflow-hidden rounded",
          position === "popper" && "translate-y-2",
          minWidthVariants({ size }),
          className
        )}
        position={position}
        {...props}
      >
        <SelectPrimitive.Viewport
          className={cn(
            "p-4",
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] max-h-[280px] w-[var(--radix-select-trigger-width)]",
            minWidthVariants({ size }),
            viewportClassName
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  )
)

DMSelectContent.displayName = SelectPrimitive.Content.displayName

const DMSelect = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Root>,
  DMSelectProps
>(
  (
    {
      children,
      placeholder,
      size,
      align = "start",
      viewportClassName,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <div className="relative overflow-hidden">
        <DMSelectBase {...props}>
          <DMSelectTrigger ref={forwardedRef} placeholder={placeholder} />
          <DMSelectContent
            size={size}
            align={align}
            viewportClassName={viewportClassName}
          >
            {children}
          </DMSelectContent>
        </DMSelectBase>
      </div>
    )
  }
)

DMSelect.displayName = "DMSelect"

const DMSelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("bg-border-neutral-faded -mx-1 my-1 h-px", className)}
    {...props}
  />
))

DMSelectSeparator.displayName = SelectPrimitive.Separator.displayName

const DMSelectGroup = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Group> & {
    title: string
  }
>(({ className, title, children, ...props }, ref) => (
  <SelectPrimitive.Group
    ref={ref}
    className={cn(
      "border-neutral-faded gap-nav-items -mx-4 mt-12 flex flex-col border-b p-4 pb-4",
      "first:mt-8 last:mb-0 last:border-b-0 last:pb-0",
      className
    )}
    {...props}
  >
    <SelectPrimitive.Label
      ref={ref}
      className="text-body-small text-neutral-faded mb-4 pl-32 font-semibold"
    >
      {title}
    </SelectPrimitive.Label>
    {children}
  </SelectPrimitive.Group>
))

DMSelectGroup.displayName = SelectPrimitive.Group.displayName

export {
  DMSelect,
  DMSelectBase,
  DMSelectGroup,
  DMSelectValue,
  DMSelectTrigger,
  DMSelectContent,
  DMSelectSeparator,
}
